import { useMemo } from "react"
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import styles from "./header.module.css"

const Header = () => {
  const location = useLocation(); 
  
  const icon = useMemo(() => {
    const blue_icon = ["/", "/home"];
    if(blue_icon.includes(location.pathname)) return "/images/icons/blue-logo-name.svg";
    else return "/images/icons/white-logo-name.svg";
  }, [location.pathname])
  
  return (
    <header>
      <div className="main_container">
        <div className={styles.content}>
          <Link to="/">
            <img loading="lazy" src={icon} alt="Scantech Logo" />
          </Link>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about-us">About us</Link></li>
            <li><Link target="_blank" to="https://construction.scantech-ks.com/">Construction</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header;
import React, { useMemo } from "react"
import SubBanner from "../../components/Banner/SubBanner";
import styles from "./smith.module.css";
import ContactForm from "../../components/Forms/Contact";
import { useParams } from 'react-router-dom';
import SCANNERS from "../../utility/scanners";
import Page from '../../routes/Page'

function SmithsDetection() {
  const { type, slug } = useParams();
  const scanner = SCANNERS[type].find(item => item.slug === slug)
  
  const scannerTypeImage = useMemo(() => {
    if(type==="smiths") return "/images/scanner_1.png"
    if(type==="astrophysics") return "/images/scanner_2.png"
    if(type==="rapiscan") return "/images/scanner_3.png"
  }, [type])

  return (
    <Page title={`X-RAY Security Scanners - ${scanner.title}`}>
      <SubBanner />
      <div className={styles.serviceBody}>
        <div className={`${styles.line2}`}>
          <div className="main_container">
            <h3>{scanner.title}</h3>
            <p>Effective air cargo screening</p>
            <img src={scanner.image} alt={scanner.title} loading="lazy" />
          </div>
        </div>
        <div className={styles.container}>
          <img src={scannerTypeImage} alt="Icon" loading="lazy" />
          {scanner.serials && scanner.serials.length > 0 && (
            <div className={`${styles.certifications} ${styles["certifications"+scanner.serials.length]}`}>
              {scanner.serials.map((i, k) => <img src={i} alt="Icon" loading="lazy" />)}
            </div>
          )}
          <div className={`main_container ${styles.text_holder}`}>
            <>{ scanner.desc}</>
            <div className={styles.boxes}>
              <div className={styles.item}>
                <div className={styles.item_holder}>
                  <h5 className={styles.title}>Highlights</h5>
                  {scanner.features.map((item, index) => (
                    <p key={index} className={styles.values}>{item}</p>
                  ))}
                </div>
                { scanner.broschure && (
                  <a href={scanner.broschure} rel="noreferrer" target="_blank">
                    <img src="/images/icons/download.svg" width={40} alt="Download icon" loading="lazy" />
                    <span>Download Broschure</span>
                  </a>
                )}
              </div>

              <div className={styles.item}>
                <div className={styles.item_holder}>
                  <h5 className={styles.title}>Technical Specification</h5>
                  {scanner.technical_specs.map((item, index) => (
                    <React.Fragment key={index}>
                      <p className={styles.label}>{item.label}</p>
                      <p className={styles.values}>{item.value}</p>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </Page>
  );
}
export default SmithsDetection;
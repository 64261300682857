import { AnimationOnScroll } from "react-animation-on-scroll";
import Grid from "../Grid/Grid";
import IconBox from "../../Boxes/IconBox";

const ServiceLayout = () => {
  const icons = [
    {
      img: "icon1.svg",
      title: "100+ lessons",
    },
    {
      img: "icon2.svg",
      title: "Tasks & Practice",
    },
    {
      img: "icon3.svg",
      title: "Accredited from The Accreditation Agency of Kosovo",
    },
  ];

  return (
    <Grid no={"CustomTrio"}>
      {icons.map((i, j) => {
        return (
          <AnimationOnScroll animateIn="animate__fadeInUp" key={j}>
            <IconBox {...i} />
          </AnimationOnScroll>
        );
      })}
    </Grid>
  );
};

export default ServiceLayout;

import { Link } from "react-router-dom";
import styles from "./box.module.css";

const ContentBox = ({ img, title, customize, children, link="/" }) => {
  return (
    <Link to={link} className={`${styles[customize]} ${styles.box}`}>
      <div className={styles.imageWrapper}>
        <img src={img} alt={title} loading="lazy" />
      </div>
      <div className={styles.content}>{children}</div>
    </Link>
  );
};

export default ContentBox;
import { Helmet } from 'react-helmet';
import 'swiper/css';
import 'swiper/css/pagination';
import Lottie from "lottie-react";
import logoBlueAnimation from '../animations/mshk-image-to-lottie.json'
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function Page({ title, desc, image, children }) {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    setLoading(true);
    let timeout = setTimeout(() => setLoading(false), 500)
    return () => clearTimeout(timeout)
  }, [location.pathname])
  return (
    <>
      <Helmet>
        <title>{title} | Scantech Group LLC</title>
        <meta property="og:title" key="og:title" content={title} />
        <meta property="og:locale" key="og:locale" content="en_US" />
        <meta property="og:type" key="og:type" content="website" />
        <meta property="og:description" key="og:description" content={`
          Scantech is a leading provider of state-of-the-art X-ray scanning solutions 
          that deliver exceptional imaging capabilities for various industries. 
          Our X-ray scanners are designed to uncover hidden details, detect threats, 
          and ensure the utmost security and accuracy in inspections.
        `} />
        <meta property="og:image" key="og:image" content={`https://scantech-ks.com/images/banner.png`} />
      </Helmet>
      {loading && (
        <div className='loader'>
          <Lottie animationData={logoBlueAnimation} loop={true} />
        </div>
      )}
      {children}
    </>
  );
}
export default Page;
import styles from "./box.module.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";

const ImageBackgroundBox = (props) => {
  return (
    <div
      id={props.elmId}
      className={styles.imageBackground}
      style={{ backgroundImage: `url("./images/${props.img}")` }}
    >
      <AnimationOnScroll animateIn="animate__fadeInDown">
        <h1>{props.title}</h1>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeInDown">
        <p>{props.desc}</p>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeInDown">
      {props.link && <Link className={styles.link} to={props.link}>{props.anchor}</Link>}
      </AnimationOnScroll>
    </div> 
  );
};

export default ImageBackgroundBox;

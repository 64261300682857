import styles from "./banner.module.css";
import Anchor from "../Anchor/Anchor";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ContentBox from "../Boxes/ContentBox";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import SCANNERS from "../../utility/scanners"

const BlueBanner = () => {
  const boxes = [
    {
      link: "/scanner-details/smiths",
      img: SCANNERS["smiths"][Math.floor(Math.random() * SCANNERS["smiths"].length)].image, 
      title: "Smiths Detection",
    },
    // { 
    //   link: "/scanner-details/astrophysics",
    //   img: SCANNERS["astrophysics"][Math.floor(Math.random() * SCANNERS["astrophysics"].length)].image, 
    //   title: "Astrophysics",
    // },
    // {
    //   link: "/scanner-details/rapiscan",
    //   img: SCANNERS["rapiscan"][Math.floor(Math.random() * SCANNERS["rapiscan"].length)].image, 
    //   title: "Rapiscan",
    // },
  ];

  return (
    <div className={styles.blue}>
      <div className={`main_container ${styles.lines}`}>
        <div className="space_between">
          <AnimationOnScroll animateIn="animate__fadeInDown">
            <h1 className="big_text">X-RAY SCANNERS</h1>
          </AnimationOnScroll>
          <Anchor title="Learn More" link="/scanner-security" customize="xrayScanners" />
        </div>
        <div className="pbt_50">
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            modules={[Pagination]}
            pagination={true}
            breakpoints={{
              0: {
                slidesPerView: 1
              },
              768:{
                slidesPerView: 2
              },
              1124: {
                slidesPerView: 3
              }
            }}
          >
            {boxes.map((i, j) => {
              return (
                <SwiperSlide key={j}>
                  <ContentBox link={i.link} title={i.title} img={i.img} customize={"blue"}>
                    <h3>{i.title}</h3>
                  </ContentBox>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default BlueBanner;

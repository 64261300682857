import styles from "./banner.module.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { HashLink } from 'react-router-hash-link';

const Banner = () => {
  const services = [
    { id: 1, name: "X-Ray Scanners", elmId: "xray-scanners" },
    { id: 2, name: "Academy", elmId: "academy" },
    { id: 3, name: "Electricity Installation", elmId: "electrity-installation" },
    { id: 4, name: "Smart-tech", elmId: "smart-tech" },
    { id: 5, name: "Professional trainings", elmId: "professional-trainings" },
  ];

  return (
    <div
      className={styles.banner}
      style={{ backgroundImage: `url('/images/banner.png')` }}
    >
      <div className="main_container">
        <div className={styles.information}>
          <AnimationOnScroll animateIn="animate__fadeInDown">
            <h3>Up, up and away with Technology!</h3>
          </AnimationOnScroll>
          <div className={styles.services}>
            {services.map((item, key) => (
              <AnimationOnScroll animateIn="animate__fadeInDown" key={key}>
                <HashLink to={"#"+item.elmId} key={item.id} className={styles.service}>
                  {item.name}
                </HashLink>
              </AnimationOnScroll>
            ))}
          </div>
          <div className={styles.banner_logo}>
            <p>Safety is a priority</p>
            <div>
              <img
                loading="lazy"
                src="/images/icons/logo-outline-color.svg"
                alt="Icon"
                width={420}
                height={420}
              />
            </div>
            <h2>Scantech</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;

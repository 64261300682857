import { useLocation } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import HeaderHandler from "./components/Header/HeaderHandler";
import AllRoutes from "./routes";
import { useEffect } from "react";

function App() {
  const location = useLocation();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  return (
    <>
      <HeaderHandler/>
      <AllRoutes />
      <Footer />
    </>
  );
}

export default App;

import { AnimationOnScroll } from "react-animation-on-scroll";
import SubBanner from "../../components/Banner/SubBanner";
import Grid from "../../components/Layout/Grid/Grid";
import Anchor from "../../components/Anchor/Anchor";
import ContentBox from "../../components/Boxes/ContentBox";
import ServiceLayout from "../../components/Layout/Content/ServiceLayout";
import styles from "./academy.module.css";
import Page from '../../routes/Page'

function Academy() {
  const boxes = [
    {
      img: "/images/academy/electricity.png",
      title: "Electricity Installation Technician",
      desc: "Master the skills needed to become an expert in electrical installations. From wiring to troubleshooting, our comprehensive program prepares you for a successful career in this in-demand field."
    },
    {
      img: "/images/academy/water_installment.png",
      title: "Plumbing Technician",
      desc: "Dive into the world of plumbing and learn how to handle installations, repairs, and maintenance. Become a skilled professional capable of solving a wide range of plumbing challenges."
    },
    {
      img: "/images/academy/xray_operator.png",
      title: "X-RAY Operator",
      desc: "Gain expertise in operating advanced X-ray equipment, ensuring accurate and efficient screenings. Learn to interpret images and contribute to the critical field of medical diagnostics and security."
    },
    {
      img: "/images/academy/digital_marketing.png",
      title: "Digital Marketing",
      desc: "Unlock the world of online advertising, social media strategies, and analytics. Learn to create impactful campaigns that drive results in the digital landscape."
    },
    {
      img: "/images/academy/graphic_design.png",
      title: "Graphic Design",
      desc: "Unleash your creativity and master the art of visual communication. Develop skills in layout design, typography, and digital tools to create stunning visuals across various mediums."
    },
    {
      img: "/images/academy/programming.png",
      title: "Full Stack Developer",
      desc: "Embark on a journey to become a versatile web developer. Acquire proficiency in both front-end and back-end development, and gain the knowledge to create dynamic and interactive websites and applications."
    },
  ];

  return (
    <Page title="Academy">
      <SubBanner title={"Academy"} dots={true} />
      <div className={`main_container pbt_100 ${styles.academy_container}`}>
        <Grid no={2}>
          <AnimationOnScroll animateIn="animate__fadeInDown">
            <h1 className={styles.heading}>
              Unlocking Skills for Success in the Digital Age
            </h1>
          </AnimationOnScroll>
          <div className="end">
            <Anchor title="Register now" link="https://moodle.scantech-ks.com/login/signup.php" />
          </div>
        </Grid>
        <div className="pbt_100">
          <Grid no={2}>
            <AnimationOnScroll animateIn="animate__fadeInDown">
              <p>
                Scantech Academy is a prestigious institution committed to empowering individuals with the 
                knowledge and skills necessary to thrive in the rapidly evolving digital landscape. 
                We offer a wide range of comprehensive courses designed to equip students 
                with practical expertise and a competitive edge in today's job market.
              </p>{" "}
              <p>
                Our academy boasts a diverse curriculum that covers five specialized courses, catering to different interests and career paths. 
                Whether you aspire to become a digital marketer, a full-stack developer, a skilled plumber, an electrician, or a creative graphic designer, 
                Scantech Academy provides the ideal platform to launch or elevate your professional journey.
              </p>{" "}
            </AnimationOnScroll>
            <ServiceLayout />
          </Grid>
        </div>
        <Grid no={3}>
          {boxes.map((i, j) => {
            return (
              <ContentBox link="" title={i.title} img={i.img} customize={"academy"} key={j}>
                <h2>{i.title}</h2>
                <p>{i.desc}</p>
              </ContentBox>
            );
          })}
        </Grid>
      </div>
    </Page>
  );
}
export default Academy;

import React, { useState } from "react";
import Hamburger from "hamburger-react";
import style from "./header.module.css";
import { Link } from "react-router-dom";

function HeaderMobile(props) {
  const [isOpen, setOpen] = useState(false);
  const alpha = Math.min(100, Math.max(0, props.scrollY > 0 ? (props.scrollY+70) : 0)) / 100;
  return (
    <div className={style.headerMobile}>
      <div className={`${style.headerMobileTop} container`} style={{ background: `rgba(0,0,0,${alpha})`}}>
        <Link to="/">
          <img loading="lazy" src="/images/icons/white-logo-name.svg" alt="Scantech Logo" />
        </Link>
        <Hamburger
          toggled={isOpen}
          toggle={setOpen}
          size={20}
          direction="right"
        />
      </div> 

      <div className={`${style.mobileHeader} ${isOpen ? style.openMenu : ""}`}>
        <div className="main_container">
          <ul>
            {props.menu &&
              props.menu.map((data, index) => (
                <li key={index} className={`${isOpen ? style.fadeIn : ""}`}>
                  <Link to={data.url} onClick={() => setOpen(false)}>
                    {data.title}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default HeaderMobile;
import { useState, useEffect } from "react";
import Header from "./Header";
import HeaderMobile from "./MobileHeader";

function HeaderHandler() {
  const [width, setWidth] = useState(window.innerWidth);
  const [scrollY, setScrollY] = useState(window.scrollY);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("scroll", handleWindowScrollChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
      window.removeEventListener("scroll", handleWindowScrollChange);
    };
  }, []);

  const handleWindowSizeChange = () => setWidth(window.innerWidth);
  const handleWindowScrollChange = () => {
    setScrollY(window.scrollY);
  }

  const isMobile = width <= 768;

  const menu = [
    { title: "Home", url: "/" },
    { title: "About us", url: "/about-us" },
    { title: "Construction", url: "https://construction.scantech-ks.com/" },
    { title: "Contact", url: "/contact" }
  ];

  return (
    <>
      {isMobile ? <HeaderMobile menu={menu} scrollY={scrollY} /> : <Header menu={menu} />}
    </>
  );
}

export default HeaderHandler;

import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
// import Scanner from "../pages/Scanner/Scanner";
import Academy from "../pages/Academy/Academy";
// import Register from "../pages/Academy/Register";
import OurPriority from "../pages/OurPriority/OurPriority";
import XRayScanners from "../pages/XRayScanners/XRayScanners";
import BaggageInspection from "../pages/BaggageInspection/BaggageInspection";
import SmithsDetection from "../pages/SmithsDetection/SmithsDetection";
import ElectricalServices from "../pages/ElectricalServices/ElectricalServices";
import SmartTech from "../pages/SmartTech/SmartTech";
import Contact from "../pages/Contact/Contact";
import AboutUs from "../pages/AboutUs/AboutUs";
 
function AllRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Home />} /> {/* Case page not found */}
      {/* <Route path="/scanner" element={<Scanner />} /> */}
      <Route path="/academy" element={<Academy />} />
      {/* <Route path="/academy/register" element={<Register />} /> */}
      <Route path="/our-priority" element={<OurPriority />} />
      <Route path="/electrical-services" element={<ElectricalServices />} />
      <Route path="/smart-tech" element={<SmartTech />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/scanner-security" element={<XRayScanners />} />
      <Route path="/scanner-details/:type" element={<BaggageInspection />} />
      <Route path="/scanner-details/:type/:slug" element={<SmithsDetection />} />
    </Routes>
  );
}

export default AllRoutes;
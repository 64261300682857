import SubBanner from "../../components/Banner/SubBanner";
import styles from "./electrical.module.css";
import ContactForm from "../../components/Forms/Contact";
import InfoBanner from "../../components/Banners/InfoBanner";
import IntroBanner from "../../components/Banners/IntroBanner";
import HalfImage from "../../components/Layout/Content/HalfImage"
import Page from '../../routes/Page'

function ElectricalServices() {
  return (
    <Page title="Electrical Services">
      <SubBanner title="Electrical Services" cssClass={styles.bannerImage} />
      <div className={styles.serviceBody}>
        <IntroBanner 
          image="/images/electrical_services_1.svg"
          title="Plumbing and Electrical Services"
          subtitle="Trusted Solutions for Your Home or Business"
          text={`
            At Scantech, we offer a comprehensive range of professional plumbing and electrical services to meet 
            the diverse needs of our residential and commercial clients. Our team of skilled technicians is dedicated to delivering reliable, 
            efficient, and cost-effective solutions that ensure the smooth operation of your plumbing and electrical systems.
          `}
        />
        <div className="main_container">
          <HalfImage 
            image="/images/electrical_services_2.png"
            title="Plumbing Services"
            reverse={true}
            customClass={styles.custom}
          >
            <p>
              Our plumbing services cover a wide array of needs, including repairs, maintenance, installations, and upgrades. 
              From fixing leaky faucets and unclogging drains to installing new fixtures and addressing plumbing emergencies, 
              our experienced plumbers have the expertise to handle any plumbing issue with precision and efficiency.
            </p>
            <p>
              We understand the importance of a properly functioning plumbing system for the comfort and convenience of your home or business. 
              Our team utilizes the latest tools and techniques to diagnose and resolve plumbing problems, ensuring that your pipes, drains, and fixtures work flawlessly. 
              Whether it's a minor repair or a complex installation, our plumbers deliver high-quality workmanship, adhering to industry standards and local regulations.
            </p>
          </HalfImage>

          <HalfImage 
            image="/images/electrical_services_3.png"
            title="Electrical Services"
          >
            <p>
              Our electrical services are designed to address all your electrical needs, from routine maintenance to complex installations and repairs. 
              We have a team of skilled electricians who are proficient in handling various electrical projects, including wiring, lighting installations, 
              electrical panel upgrades, troubleshooting, and safety inspections.
            </p>
            <p>
              Safety is our utmost priority when it comes to electrical services. We follow stringent safety protocols and adhere to industry regulations to provide 
              you with reliable and secure electrical solutions. Our electricians are experienced in working with different electrical systems and components, 
              ensuring that your home or business is powered safely and efficiently.
            </p>
          </HalfImage>
        </div>

        <div className={styles.large_banner}>
          <img src="/images/electrical_services_4.png" alt="Large banner" loading="lazy" />
          <div className={styles.content}>
            <img src="/images/electrical_services_5.svg" alt="Large banner" width={99} loading="lazy" />
            <p>
              Whether you need assistance with electrical repairs, lighting upgrades, circuit installations, 
              or electrical system troubleshooting, our team is dedicated to delivering top-notch service 
              and customer satisfaction. We take the time to understand your specific requirements and 
              provide personalized solutions that meet your needs and budget.
            </p>
            <img src="/images/icons/logo-white.svg" alt="Large banner" width={77} loading="lazy" />
          </div>
        </div>

        <InfoBanner title="Why Choose Scantech for Plumbing and Electrical Services?">
          <p>Highly skilled technicians with extensive experience in plumbing and electrical work.</p>
          <p>Commitment to safety, adhering to industry standards and regulations.</p>
          <p>Reliable and efficient solutions that ensure long-lasting performance.</p>
          <p>Transparent pricing and honest communication throughout the service process.</p>
          <p>Exceptional customer service, striving to exceed your expectations.</p>
          <p>
            At Scantech, we take pride in our ability to provide comprehensive
            plumbing and electrical services that you can trust. 
          </p>
          <p>
            Whether you need routine maintenance, emergency repairs, or system 
            installations, we are here to deliver reliable and efficient solutions for
            your home or business. 
          </p>
          <p>
            Contact us today to discuss your plumbing and electrical needs 
            and let our experienced team take care of your requirements.
          </p>
        </InfoBanner>
        <ContactForm />
      </div>
    </Page>
  );
}
export default ElectricalServices;

import styles from "./iconBox.module.css";

const IconBox = ({ img, title }) => {
  return (
    <div className={styles.iconBox}>
      <div>
        <img loading="lazy" src={`../images/${img}`} alt="box" />
      </div>
      <h3>{title}</h3>
    </div>
  );
};

export default IconBox;

import styles from "./banner.module.css";

const IntroBanner = ({ image, title, subtitle, text }) => {
  return (
    <div className={styles.intro_container}>
      <div className="main_container">
        <div className={styles.sub_banner}>
          <div className={styles.img}>
            <img src={image} alt={title} loading="lazy" />
          </div>
          <div className={styles.text}>
            <h2>{title}</h2>
            {subtitle && <h3>{subtitle}</h3>}
            <p>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroBanner;

import SubBanner from "../../components/Banner/SubBanner";
import styles from "./contact.module.css";
import ContactForm from "../../components/Forms/Contact";
import Page from '../../routes/Page'

function Contact() {
  return (
    <Page title="Contact">
      <SubBanner cssClass={styles.contact_banner} />
      <div className={styles.contact_container}>
        <div className={styles.contact_inner_container}>
          <ContactForm 
            isSmall={false} 
            showIcon={false} 
            showLine={false} 
            customContainer={styles.contact_form} 
          />

          <div className={styles.contact_data}>
            <div className={styles.info_container}>
              <div className={styles.info}>
                <div className={styles.heading}>
                  <h4>Kosovë</h4>
                  <span>Scantech Group LLC</span>
                </div>
                <p>
                  Office 1<br/>
                  Mati 1 Prishtinë
                </p>
                <p>
                  Office 2<br/>
                  Rr. Tahir Sinani Lipjan
                </p>
                <p>
                  +383 38 58 00 00<br/>
                  +383 48 36 33 33
                </p>
                <p>info@scantech-ks.com</p>
              </div>
              <div className={styles.info}>
                <div className={styles.heading}>
                  <h4>United Kingdom</h4>
                  <span>Scantech Agency LTD</span>
                </div>
                <p>
                  Address<br/>
                  27 Old Gloucester Street, London, WC1N 3AX
                </p>
                <p>info@scantech-ks.com</p>
              </div>
            </div>
            <div className={styles.map}>
              <iframe 
                title="Scantech Group Location"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11762.274076846556!2d21.1197468!3d42.5219766!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135483705a1ca753%3A0x20da33af0a5172b0!2sScantech%20Group!5e0!3m2!1sen!2s!4v1695390029531!5m2!1sen!2s" 
                width="100%" 
                height="100%" 
                allowFullScreen="" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
export default Contact;

import styles from "./banner.module.css";

const SubBanner = ({data, title, dots = false, cssClass = ""}) => {
  return (
    <div className={`${styles.subBanner} ${dots && "dots academy"} ${cssClass}`}>
      <div className="main_container">
        { title && <h1 className="big_text">{title}</h1> }
        {data && <div className={styles.subBannerText}>
          { data?.map((i, j) => {
            return <div key={j}>{i}</div>;
          })}
        </div>}
      </div>
    </div>
  );
};

export default SubBanner;

import SubBanner from "../../components/Banner/SubBanner";
import styles from "./scanners.module.css";
import ContactForm from "../../components/Forms/Contact";
import Slider from "../../components/Slider/Slider";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Anchor from "../../components/Anchor/Anchor";
import Page from '../../routes/Page'

function XRayScanners() {
  return (
    <Page title="X-RAY Security Scanners">
      <SubBanner title="X-RAY Security Scanners" cssClass={styles.bannerImage} />
      <div className={styles.serviceBody}>
        <div className={`main_container ${styles.center}`}>
          <div className={styles.scanners}>
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              modules={[Pagination]}
              pagination={true}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768:{
                  slidesPerView: 1,
                },
                1124:{
                  slidesPerView: 1
                }
              }}
            >
              <SwiperSlide>
                <div className={styles.item}>
                  <div className={styles.image_container}>
                    <img src="/images/scanner_1.png" alt="Scanner 1" loading="lazy" />
                  </div>
                  <h4>Empowering Security and Efficiency</h4>
                  <p>
                    Smiths Detection is a renowned name in the field of security technology, and their luggage scanners are 
                    trusted worldwide for their exceptional performance and reliability. Designed with advanced imaging capabilities, 
                    Smiths Detection scanners provide an unparalleled level of security and efficiency in detecting threats and contraband within luggage.
                  </p>
                  <p>
                    With cutting-edge technology and intelligent algorithms, Smiths Detection scanners offer detailed and clear images that 
                    enable security personnel to identify potential risks swiftly and accurately. These scanners are equipped with advanced features 
                    such as automated threat detection, dual-energy imaging, and enhanced resolution, ensuring effective screening while minimizing false alarms.
                  </p>
                  <p>
                    Smiths Detection X-ray scanners are known for their user-friendly interfaces and intuitive operation, making them 
                    suitable for deployment in various environments, including airports, transportation hubs, government facilities, and high-security venues. 
                    These scanners seamlessly integrate into existing security systems, optimizing the overall screening process and enhancing throughput without compromising on security.
                  </p>
                  <p>
                    From identifying prohibited items to detecting concealed weapons and explosives, Smiths Detection luggage 
                    scanners provide an essential layer of security to protect individuals and assets. Their commitment to continuous 
                    innovation and technological advancements ensures that their scanners stay at the forefront of security solutions, 
                    providing peace of mind and safeguarding public safety
                  </p>
                  <Anchor link="/scanner-details/smiths" title={"View models"} customize="mt25" />
                </div>
              </SwiperSlide>
              {/* <SwiperSlide>
                <div className={styles.item}>
                  <div className={styles.image_container}>
                    <img src="/images/scanner_2.png" alt="Scanner 1" loading="lazy" />
                  </div>
                  <h4>Unparalleled Precision and Threat Detection</h4>
                  <p>
                    Astrophysics is a leading manufacturer of state-of-the-art luggage scanners, renowned for their precision, 
                    reliability, and advanced threat detection capabilities. Built with cutting-edge imaging technology, 
                    Astrophysics scanners offer exceptional image quality and clarity, enabling security personnel to 
                    identify even the smallest details and potential threats with utmost accuracy.
                  </p>
                  <p>
                    Astrophysics x-ray scanners utilize advanced algorithms and sophisticated image processing techniques to deliver high-resolution images, 
                    enhancing the ability to detect concealed weapons, explosives, narcotics, and other contraband items. These scanners are designed 
                    to meet the demanding requirements of airports, customs facilities, and other high-security environments, where quick and reliable threat detection is crucial.
                  </p>
                  <p>
                    With a focus on both performance and operational efficiency, Astrophysics scanners offer features such as rapid image processing, 
                    dual-energy scanning, and automatic threat recognition. These advanced functionalities enable security personnel to conduct thorough 
                    inspections while minimizing false alarms, ultimately streamlining the screening process and enhancing throughput.
                  </p>
                  <p>
                    Astrophysics scanners are built to withstand the rigors of continuous operation, ensuring durability and longevity 
                    in high-volume screening environments. They are backed by comprehensive service and support, ensuring that clients 
                    receive timely maintenance, upgrades, and technical assistance to maximize the performance and lifespan of the scanners.
                  </p>
                  <Anchor link="/scanner-details/astrophysics" title={"View models"} customize="mt25" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.item}>
                  <div className={styles.image_container}>
                    <img src="/images/scanner_3.png" alt="Scanner 1" loading="lazy" />
                  </div>
                  <h4>Enhanced Security with Cutting-Edge Technology</h4>
                  <p>
                    Rapiscan is a trusted provider of innovative luggage scanning solutions known for their commitment to advancing security technologies. 
                    Rapiscan luggage scanners are designed to deliver enhanced security measures with cutting-edge features and intelligent threat detection capabilities.
                  </p>
                  <p>
                    Rapiscan scanners employ advanced imaging technologies, such as dual-energy X-ray and multi-view imaging, to provide detailed and precise images of scanned luggage. 
                    These high-resolution images enable security personnel to detect concealed threats, such as weapons, explosives, and contraband, with increased accuracy and efficiency.
                  </p>
                  <p>
                    With an emphasis on user-friendliness, Rapiscan scanners feature intuitive interfaces and ergonomic designs, ensuring ease of operation for security personnel. 
                    Their scanners are designed to integrate seamlessly into existing security infrastructure, allowing for efficient and effective screening processes in airports, 
                    government facilities, and other security-conscious environments.
                  </p>
                  <p>
                    Rapiscan scanners are backed by continuous research and development efforts, ensuring that their technology remains at the forefront of security solutions. 
                    By incorporating advanced algorithms, artificial intelligence, and machine learning capabilities, Rapiscan scanners constantly evolve to address 
                    emerging threats and challenges in the security landscape.
                  </p>
                  <p>
                    Rapiscan's commitment to customer satisfaction extends beyond their cutting-edge technology. 
                    hey provide comprehensive training, maintenance support, and service options.
                  </p>
                  <Anchor link="/scanner-details/rapiscan" title={"View models"} customize="mt25" />
                </div> 
              </SwiperSlide> */}
            </Swiper>
          </div>

          {/* <div className="xrayScanners">
            <Slider 
              title="Find your Perfect Product"
              subtitle="Baggage and parcel inspection (BPI)"
              anchor={{ to: "", title: "Explore the entire BPI range" }}
              items={[
                {
                  image: "/images/scanner_slider_1.png",
                  points: ["Checkpoint", "Pallet and Cargo"]
                },
                {
                  image: "/images/scanner_slider_2.png",
                  points: ["Large Parcel and small Cargo", "Check-in"]
                },
                {
                  image: "/images/scanner_slider_3.png",
                  points: ["Check-in", "Mail and small parcel"]
                }
              ]}
            />
          </div> */}
        </div>
        <ContactForm />
      </div>
    </Page>
  );
}
export default XRayScanners;

import SubBanner from "../../components/Banner/SubBanner";
import styles from "./smart.module.css";
import ContactForm from "../../components/Forms/Contact";
import InfoBanner from "../../components/Banners/InfoBanner";
import IntroBanner from "../../components/Banners/IntroBanner";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Grid } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/grid';
import Page from '../../routes/Page'

function SmartTech() {
  return (
    <Page title="Smart-Tech">
      <SubBanner title="Smart-Tech" cssClass={styles.bannerImage} />
      <div className={styles.serviceBody}>
        <IntroBanner 
          image="/images/smart_tech_1.svg"
          title="Transforming Your Home or Business into an Intelligent Space"
          text={`
            At Scantech, we specialize in providing cutting-edge smart tech services that enhance the functionality, efficiency, and convenience of your home or business. 
            With our expertise in the latest smart technologies, we can help you harness the power of automation and connectivity to create an intelligent space tailored to your specific needs.
          `}
        />

        <div className="main_container">
          <div className={styles.boxes_container}>
            <img src="/images/smart_tech_2.png" alt="Banner" loading="lazy" />
            <div className={styles.boxes}>
              <Swiper
                slidesPerView={2}
                grid={{ rows: 2, fill: "row" }}
                spaceBetween={60}
                modules={[Grid, Pagination]}
                pagination={true}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    grid: { rows: 1, fill: "row" }
                  },
                  768:{
                    slidesPerView: 2,
                    grid: { rows: 1, fill: "row" }
                  },
                  1124: {
                    slidesPerView: 2,
                    grid: { rows: 2, fill: "row" }
                  }
                }}
                className="smartTechSwiper"
              >
                <SwiperSlide>
                  <div className={styles.box}>
                    <h3>Smart Home Automation</h3>
                    <p>
                      Transform your home into a smart haven with our comprehensive smart home automation solutions. 
                      We offer seamless integration of various devices and systems, including lighting, HVAC, security, entertainment, and more. With smart home automation, 
                      you can control and monitor your home remotely, optimize energy usage, enhance security, and create personalized environments that cater to your lifestyle preferences.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.box}>
                    <h3>Commercial Automation Solutions</h3>
                    <p>
                      Transform your home into a smart haven with our comprehensive smart home automation solutions. 
                      We offer seamless integration of various devices and systems, including lighting, HVAC, security, entertainment, and more. With smart home automation, 
                      you can control and monitor your home remotely, optimize energy usage, enhance security, and create personalized environments that cater to your lifestyle preferences.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.box}>
                    <h3>Smart Security Systems</h3>
                    <p>
                      Protect your home or business with our state-of-the-art smart security systems. 
                      We offer a range of solutions, including smart surveillance cameras, access control systems, and intrusion detection systems. 
                      Our smart security systems allow for remote monitoring and control, real-time alerts, and integration with other smart devices. 
                      Rest easy knowing that your property is protected with advanced security technology tailored to your specific requirements.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.box}>
                    <h3>Energy Management Solutions</h3>
                    <p>
                      Our smart tech services extend to energy management solutions that help you optimize energy usage and reduce costs. 
                      We offer smart thermostats, energy monitoring systems, and smart lighting solutions that allow for precise control and automation of energy-consuming devices. 
                      By leveraging smart energy management, you can create an eco-friendly environment, lower utility bills, and contribute to a sustainable future.
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>

        <InfoBanner title="Why Choose SCANTECH for Smart Tech Services?">
          <p>Extensive expertise in smart technologies and automation systems.</p>
          <p>Customized solutions tailored to your specific needs and preferences.</p>
          <p>Integration of diverse smart devices and systems for seamless operation.</p>
          <p>Commitment to quality, reliability, and long-lasting performance.</p>
          <p>Professional installation and comprehensive customer support.</p>
          <p>
            At Scantech, we believe in the transformative power of smart technologies to 
            revolutionize the way you live and work. Our smart tech services are designed to 
            simplify your life, enhance security, optimize energy usage, and create a connected 
            ecosystem that adapts to your lifestyle or business requirements.
          </p>
          <p>
            Embrace the future of smart living and let us guide you in turning your home 
            or business into a truly intelligent space. Contact us today to explore the possibilities 
            of smart tech services.
          </p>
        </InfoBanner>
        <ContactForm />
      </div>
    </Page>
  );
}
export default SmartTech;

import { Link } from "react-router-dom";
import styles from "./footer.module.css";
import Anchor from "../Anchor/Anchor";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useMemo } from "react"
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation(); 
  
  const shouldIgronContactBttn = useMemo(() => {
    const ignoreOn = ["/scanner-security", "/baggage-inspection", "/smiths-detection"];
    return ignoreOn.includes(location.pathname)
  }, [location.pathname])

  const shouldHideFooter = useMemo(() => {
    const ignoreOn = ["/contact", "/about-us", "/academy/register"];
    return ignoreOn.includes(location.pathname)
  }, [location.pathname])

  return !shouldHideFooter && (
    <footer>
      <div className="main_container">
        <div className={styles.footerText}>
          <AnimationOnScroll animateIn="animate__fadeInDown">
            <h1>Let’s go Up, and up away with Technology!</h1>
          </AnimationOnScroll>
          { !shouldIgronContactBttn && <Anchor title="Contact Us" link="/contact" /> }
        </div>
        <Link to="/">
          <img loading="lazy" src="/images/icons/logo-blue.svg" alt="Scantech Logo" />
        </Link>
        <p className={styles.copyright}>© 2024 Scantech Group LLC</p>
      </div>
    </footer>
  );
};

export default Footer;

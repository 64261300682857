import SubBanner from "../../components/Banner/SubBanner";
import styles from "./baggage.module.css";
import ContactForm from "../../components/Forms/Contact";
import ContentBox from "../../components/Boxes/ContentBox";
import Anchor from "../../components/Anchor/Anchor";
import Slider from "../../components/Slider/Slider";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/grid';
import HalfImage from "../../components/Layout/Content/HalfImage";
import { useParams } from "react-router-dom";
import SCANNERS from "../../utility/scanners";
import Page from '../../routes/Page'

function BaggageInspection() {
  const { type } = useParams();
  const boxes = SCANNERS[type];
  const feature = SCANNERS[type][Math.floor(Math.random() * SCANNERS[type].length)];

  return (
    <Page title="X-RAY Security Scanners">
      <SubBanner cssClass={styles.removeBanner} />
      <div className={styles.serviceBody}>
        <div className={`${styles.line}`}>
          <div className={`main_container`}>
            <h4 className={styles.title}>Baggage and parcel inspection (BPI)</h4>
            <HalfImage 
              image={feature.image}
              title={feature.title}
              text={feature.short_desc}
              link={{to: `/scanner-details/${type}/${feature.slug}`, title: "Features & Options"}}
              customClass={styles.intro_banner}
            />
          </div> 
        </div>
        <div className={`${styles.line2}`}>
          <div className={`main_container ${styles.gridBoxes}`}>
            <Swiper
              slidesPerView={3}
              grid={{ rows: 1, fill: "row" }}
              spaceBetween={30}
              modules={[Grid, Pagination]}
              pagination={true}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  grid: { rows: 1, fill: "row" }
                },
                768:{
                  slidesPerView: 2,
                  grid: { rows: 1, fill: "row" }
                },
                1124:{
                  slidesPerView: 3,
                  grid: { rows: 2, fill: "row" }
                }
              }}
            >
              {boxes.map((i, j) => {
                return (
                  <SwiperSlide key={j}>
                    <ContentBox link={`/scanner-details/${type}/${i.slug}`} title={i.title} img={i.image} customize={"black"}>
                      <div>
                        <h2>{i.title}</h2>
                        {i.features && (
                          <ul>
                            {i.features.map((f, i) => <li key={i}>{f}</li>)}
                          </ul>
                        )}
                      </div>
                      <Anchor link={`/scanner-details/${type}/${i.slug}`} title="View more" type="black" />
                    </ContentBox>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
        {/* <div className={`main_container baggageInspection`}>
          <Slider 
            slidesPerView={1}
            title="Find your Perfect Product"
            subtitle="People Screening (PS)"
            anchor={{ to: "", title: "Explore the entire BPI range" }}
            items={[
              {
                image: "/images/baggage_inspection.png",
                points: ["Metor metal detectors"]
              },
              {
                image: "/images/baggage_inspection.png",
                points: ["Metor metal detectors"]
              }
            ]}
          />
        </div> */}
      </div>
      <ContactForm />
    </Page>
  );
}
export default BaggageInspection;
import SubBanner from "../../components/Banner/SubBanner";
import styles from "./aboutus.module.css";
import Page from '../../routes/Page'

function AboutUs() {
  return (
    <Page title={"About us"}>
      <SubBanner cssClass={styles.about_banner} />
      <div className={styles.about_container}>
        <div className={`main_container`}>
          <div className={styles.intro_container}>
            <div className={styles.sub_banner}>
              <div className={styles.text}>
                <h2>Who are we?</h2>
                <p>
                  Welcome to Scantech LLC, a leading provider of X-ray solutions and a wide range of professional services. 
                  Founded on January 29, 2023, by Agron Mejzinolli, a renowned X-ray specialist, 
                  Scantech is committed to delivering exceptional products and services to meet your needs.
                </p>
                <p>
                  At Scantech, we take pride in our expertise and dedication to providing high-quality solutions. 
                  As the only specialized company in the Balkans for X-ray machine repair and maintenance, we offer unparalleled knowledge and experience in this field. 
                  Our extensive range of services includes X-ray machine repairs, installations, and maintenance, ensuring optimal performance and safety.
                </p>
                <p>
                  We have established strong partnerships with industry-leading brands such as Smiths Detection, Rapiscan, and Astrophysics. 
                  These collaborations enable us to offer cutting-edge technology and ensure that our customers receive the best solutions available on the market.
                </p>
              </div>
              <div className={styles.img}></div>
            </div>
          </div>
        </div>

        <div className={styles.large_banner}>
          <img src="/images/about_us_banner.png" alt="About us banner" loading="lazy" />
          <div className={styles.text}>
            <h2>Up, up and away with Technology!</h2>
          </div>
        </div>

        <div className="main_container">
          <div className={styles.boxes}>
            <div className={styles.box}>
              <img src="/images/about_us_1.svg" alt="Our Academy" loading="lazy" />
              <h3>Our Academy</h3> 
              <p>
                As part of our commitment to education and professional development, 
                Scantech operates an Academy that offers five comprehensive professional courses. 
                These courses are designed to enhance your skills and knowledge in the field of X-ray technology. 
              </p>
              <p>
                Whether you are a beginner or an experienced professional, our Academy has the right course to suit your needs.
              </p>
            </div>
            <div className={styles.box}>
              <img src="/images/about_us_2.svg" alt="Our Services" loading="lazy" />
              <h3>Our Services</h3> 
              <p>
                In addition to our specialization in X-ray machine repair and maintenance, 
                Scantech provides a range of other services to meet the diverse needs of our customers. Our services include:
              </p>
              <p>
                Electricity & Plumbing Installations: Our skilled technicians ensure efficient and reliable electrical and plumbing installations, 
                catering to both residential and commercial clients.
              </p>
              <p>
                Digital Marketing Solutions: Our team of digital marketing experts helps businesses establish a strong online presence, 
                maximize their reach, and drive growth through effective digital marketing strategies.
              </p>
              <p>
                E-commerce Solutions: We offer comprehensive e-commerce solutions, including website development, 
                payment integration, inventory management, and marketing support, enabling businesses to thrive in the online marketplace
              </p>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
export default AboutUs;
import styles from "./banner.module.css";

const InfoBanner = ({ title, children }) => {
  return (
    <div className={styles.box}>
      <h2>{title}</h2>
      {children}
    </div>
  );
};

export default InfoBanner;

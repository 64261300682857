import SubBanner from "../../components/Banner/SubBanner";
import styles from "./ourpriority.module.css";
import Page from '../../routes/Page'

function OurPriority() {
  return (
    <Page title="Our Priority">
      <SubBanner cssClass={styles.removeBanner} />
      <div className={styles.serviceBody}>
        <div className={`main_container ${styles.center}`}>
          <div className={styles.shield_container}>
            <img src="/images/shield.svg" alt="Shield" loading="lazy" />
            <p>
              At Scantech, safety is our top priority in everything we do. <br/>
              We are committed to providing services that not only meet our clients' 
              needs but also ensure the highest levels of safety and security.
            </p>
          </div>
          <p>
            With our X-ray scanning services, we adhere to stringent safety protocols to protect individuals and assets. 
            Our certified technicians are well-trained in operating and maintaining X-ray scanners from reputable brands like Smiths Detection, Astrophysics, and Rapiscan. 
            We prioritize regular maintenance and rigorous inspections to guarantee that the scanners are functioning optimally and safely. 
            Whether it's for security screening or product inspection, our X-ray scanning services are conducted with the utmost care and precision.
          </p>
          <p>
            When it comes to Scantech Academy, safety is integrated into our training programs. 
            We understand the importance of creating a safe and inclusive learning environment for our students. 
            Our instructors not only provide in-depth knowledge and practical skills but also emphasize safety measures in each course. 
            Whether it's learning about electrical safety, plumbing regulations, or digital marketing ethics, 
            we ensure that our students are equipped with the necessary knowledge to prioritize safety in their respective fields.
          </p>
          <div className={styles.two_images}>
            <img src="/images/our_priority_2.png" alt="Asset 1" loading="lazy" />
            <img src="/images/our_priority_3.jpg" alt="Asset 1" loading="lazy" />
          </div>
          <p>
            In our plumbing and electrical services, safety is paramount. 
            Our highly skilled technicians follow industry standards and regulations to ensure safe installations, 
            repairs, and maintenance of plumbing and electrical systems. 
            We conduct thorough assessments and provide reliable solutions that prioritize the safety of our 
            clients and their properties. From residential homes to large-scale industrial projects, 
            we are committed to delivering exceptional services without compromising on safety.
          </p>
          <p>
            When integrating smart technology, safety considerations are at the forefront of our solutions. 
            Our team of experts carefully design and implement smart tech systems that prioritize data security, 
            privacy, and user safety. Whether it's setting up home automation systems or optimizing building management 
            with IoT devices, we employ robust security measures to protect against cyber threats and ensure 
            the safety of our clients' networks and connected devices.
          </p>
          <p>
            In our professional training programs, safety awareness plays a crucial role. 
            We emphasize the importance of maintaining a safe and respectful work environment, teaching participants 
            how to identify and mitigate potential risks. From promoting ergonomics to fostering a culture of safety, 
            our training programs empower individuals to prioritize safety in their work practices 
            and contribute to a safer overall workplace.
          </p>
          <p>
            At Scantech, we are dedicated to providing services that prioritize safety at every step. 
            We continuously strive to uphold the highest safety standards in all our operations, ensuring the well-being and peace of mind of our clients and partners.
          </p>

          <div className={styles.eye_container}>
            <img src="/images/our_priority_eye.svg" alt="Asset 1" loading="lazy" />
            <h4>Scantech X-ray Scanners:</h4>
            <h3>Unveiling Hidden Details with<br/>Precision and Security</h3>
          </div>

          <p>
            Scantech is a leading provider of state-of-the-art X-ray scanning solutions that deliver 
            exceptional imaging capabilities for various industries. Our X-ray scanners are designed to uncover hidden details, 
            detect threats, and ensure the utmost security and accuracy in inspections.
          </p>
          <p>
            With a focus on precision and reliability, our X-ray scanners employ advanced imaging 
            technology to penetrate materials and provide detailed imagery of their contents. 
            Whether it's for security screening in airports, customs checkpoints, or high-security facilities, 
            or for quality control and product inspection in manufacturing and logistics industries, 
            our X-ray scanners are equipped to meet the diverse needs of our clients.
          </p>
          <p>
            At Scantech, we understand that safety is paramount. That's why our X-ray scanning services 
            prioritize adherence to strict safety protocols. Our certified technicians have extensive training 
            and expertise in operating and maintaining X-ray scanners from renowned brands such as Smiths Detection, 
            Astrophysics, and Rapiscan. We conduct regular maintenance checks and rigorous inspections to ensure 
            that our scanners function optimally, providing reliable and secure results.
          </p>
          <img src="/images/our_priority_four.svg" alt="Asset 1" className={styles.spacing} width={770} loading="lazy" />
          <p>
            Our X-ray scanning services encompass a wide range of applications. From detecting hidden contraband, 
            weapons, and illicit substances to inspecting baggage, packages, and cargo for potential threats, 
            our scanners offer an additional layer of security for public and private spaces. In industrial settings, 
            our X-ray scanners aid in quality control, enabling precise inspection of products, components, 
            and materials for any anomalies or defects
          </p>
          <p>
            At Scantech, we understand the importance of flexibility and customization. 
            Our X-ray scanners are designed to accommodate various sizes and shapes of objects, 
            offering adjustable conveyor speeds, multiple energy levels, and configurable imaging parameters. 
            This versatility allows for seamless integration into existing security checkpoints, production lines, 
            or inspection processes, enhancing operational efficiency while maintaining a high level of security.
          </p>
          <p>
            We take pride in our commitment to customer satisfaction. Our team of dedicated professionals 
            works closely with clients to understand their unique requirements and recommend the most 
            suitable X-ray scanning solutions. Whether you need a standalone scanner or a fully integrated scanning system, 
            we offer personalized consultations, installation services, and ongoing support to ensure optimal 
            performance and peace of mind.
          </p>
        </div>
      </div>
    </Page>
  );
}
export default OurPriority;

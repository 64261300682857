import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import styles from "./form.module.css";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import api from "../../utility/axios";

function ContactForm({ customContainer="", showIcon=true, showLine=true, isSmall=true }) {
  const [message, setMessage] = useState(null);
  const ContactSchema = Yup.object().shape({
    fullname: Yup.string().required(), 
    country: Yup.string().required(),
    organization: Yup.string().required(),
    email: Yup.string().email("Invalid email").required(),
    phone: Yup.string().required(),
    interested_in: Yup.string().required(),
    hear_about_us: Yup.string().required(),
    comments: Yup.string().required(),
    agree_to_terms: Yup.boolean().oneOf([true], '')
  });

  return (
    <div className={`${styles.contactWrapper} ${showLine && styles.line} ${customContainer}`}>
      <div className={` ${isSmall && styles.small}`}>
        <div className={styles.heading}>
          <h1>Contact Us</h1>
          {showIcon && <img loading="lazy" src="/images/icons/logo-white.svg" alt="Icon" />}
        </div>
        <Formik
          initialValues={{
            fullname: "",
            country: "",
            organization: "",
            email: "",
            phone: "",
            interested_in: "",
            hear_about_us: "",
            comments: "",
            agree_to_terms: false,
            receive_emails: false
          }}
          validationSchema={ContactSchema}
          onSubmit={async (values) => {
            if(message?.success === true) return;
            setMessage({ sending: true });
            const response = await api.post('/register/email', values);
            setMessage({ sending: false, success: response.data });
          }}
        >
          {({ isValid }) => (
            <Form>
              {!isValid && <p className={styles.error}>Please fill in all the fields marked with: *.</p>}
              <div className={styles.inputSingle}>
                <Field name="fullname" placeholder="Full Name *" autoComplete="off" />
              </div>
              <div className={styles.inputWrapper}>
                <Field name="country" placeholder="Country *" autoComplete="off" />
                <Field name="organization" placeholder="Organization *" autoComplete="off" />
              </div>
              <div className={styles.inputWrapper}>
                <Field name="email" placeholder="Email *" autoComplete="off" />
                <Field name="phone" placeholder="Phone number *" autoComplete="off" />
              </div>
              <div className={styles.inputWrapper}>
                <div>
                  <div className={styles.inputSingle}>
                    <Field name="interested_in" placeholder="I'm interested in *" autoComplete="off" />
                  </div>
                  <div className={styles.inputSingle}>
                    <Field name="hear_about_us" placeholder="How did you hear about us? *" autoComplete="off" />
                  </div>
                </div>
                <div className={styles.textarea}>
                  <label>Comments *</label>
                  <Field as="textarea" type="text" name="comments" autoComplete="off" />
                </div>
              </div>
              <label className={styles.checkbox}>
                <Field type="checkbox" name="agree_to_terms" />
                <p className={styles.label_font}>
                  * By clicking Submit I agree to Scantech Group Inc.’s{" "}
                  <Link to="">Privacy and Cookies Policy</Link> and{" "}
                  <Link to="">Terms and Conditions.</Link>
                </p>
                <span></span>
              </label>
              <label className={styles.checkbox}>
                <Field type="checkbox" name="receive_emails" />
                <p className={styles.label_font}>
                  I would like to receive futur communication from Scantech Group Inc.
                </p>
                <span></span>
              </label>
              {message?.success ? (
                <p className={styles.success}>Message sent successfully.</p>
              ) : (
                <button type="submit" disabled={message?.sending}>{message?.sending ? "Sending email..." : "Submit"}</button>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
export default ContactForm;

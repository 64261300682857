import { Link } from "react-router-dom";
import styles from "./anchor.module.css"

const Anchor = ({link, title, type="blue", customize}) => {
  return (
    <Link to={link} className={`${styles.anchor} ${styles[type]} ${styles[customize]}`}>
      {title}
    </Link>
  )
}

export default Anchor;
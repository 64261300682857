import { AnimationOnScroll } from "react-animation-on-scroll";
import Anchor from "../Anchor/Anchor";
import styles from "./serviceBanner.module.css";

const ServiceBanner = (props) => {
  return (
    <div
      id={props.elmId}
      className={styles.serviceBanner}
      style={{ backgroundImage: `url('/images/${props.image}')` }}
    >
      <div className="main_container">
        <AnimationOnScroll animateIn="animate__fadeInDown">
          <h1 className="big_text">{props.title}</h1>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInDown">
          <div className={styles.serviceBannerText}>
            <div>
              <p>{props.desc}</p>
            </div>

            {props?.anchor && (
              <Anchor link={props?.link} title="Learn more about this service" />
            )}
          </div>
        </AnimationOnScroll>
      </div>
    </div>
  );
};

export default ServiceBanner;

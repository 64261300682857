
import Anchor from "../../Anchor/Anchor";
import styles from "./half.module.css";

const HalfImage = ({ title, text, children, image, reverse=false, customClass="", elmId, link }) => {
  elmId = elmId?elmId:"";
  return (
    <div id={elmId} className={`${styles.half_image} ${reverse && styles.reverse} ${customClass}`}>
      <div className={styles.image}>
        <img src={image} alt={title} loading="lazy" />
      </div>
      <div className={styles.text}>
        <h2>{title}</h2>
        {text && <p>{text}</p> }
        {children && children}
        <div className={styles.link}>
          {link && <Anchor title={link.title} link={link.to} customize="halfImage" type={link?.transparent?"transparent":"blue"} />}
        </div>
      </div>
    </div>
  );
};

export default HalfImage;

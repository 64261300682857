const SCANNERS = {
  smiths: [
    {
      title: "HI-SCAN 7555 DV",
      slug: "hi-scan-7555-dv",
      short_desc: `
        HI-SCAN 7555 DV is an advanced dual-view X-ray inspection system for automatic detection of explosives. 
        The dual-view increases operational efficiency by eliminating the need to re-position or re-scan objects.
      `,
      desc: 
        <>
          <p>HI-SCAN 7555 DV is an advanced dual-view X-ray inspection system for automatic detection of explosives. The dual-view increases operational efficiency by eliminating the need to re-position or re-scan objects.</p>
          <p>Offering superior image quality, smart display functions and easy integration into tray handling and management systems, it is the ideal solution for efficient screening at airports, government buildings, hotels and other critical infrastructure applica- tions.</p>
          <p>ACSTL listing (planned), in combination with the optional ECAC EDS CB C1 certifica- tion and iCMORE Weapons capability for automatic detection of pistols, revolvers, gun parts, flick and fixed blade knives as well as iCMORE Currency capability, increas- es security, efficiency, and automation, without requiring additional hardware. Click here to learn more.</p>
          <img src="/images/smiths/35168-8373936.jpeg" alt="Banner" loading="lazy" />
          <p>The HI-SCAN 7555 DV also provides high levels of protection against cyber threats by applying industry best practices such as operating system hardening, a host-based firewall, TripWire integrity monitoring and powerful user management with highly configurable password policies.</p>
          <p>HI-SCAN 7555 DV is compatible with the Checkpoint.Evoplus screening and manage- ment platform which supports actionable operational data insights and health status monitoring as well as centralised screening.</p>
          <p>Easy integration into Smiths Detection or third-party tray handling lanes makes it an ideal choice for high-throughput applications.</p>
        </>,
      image: "/images/smiths/HI_SCAN_7555_DV.png",
      features: [
        'ECAC EDS CB C1 approved',
        'Automatic explosives detection capability',
        'Smart display functions',
        'High level of cyber security',
        'Compact footprint - weight 1050 kg/2315 lbs',
        'Optional automatic weapon and/or currency detection',
      ],
      technical_specs: [
        {label: "Dimensions", value: "2590 (L) x 1450 (W) x 1512 (H) [mm]"},
        {label: "Weight", value: "Approx. 1050 kg [2315 lbs]"},
        {label: "Tunnel dimensions", value: "755 (W) x 555 (H) [mm] 29.7 (W) x 21.9 (H) [in]"},
        {label: "Conveyor Speed", value: "0.2/0.24 [m/s]"}
      ],
      serials: [
        "/images/serials/icmore_weapons_blue.svg",
        "/images/serials/icmore_currency_blue.svg",
        "/images/serials/ecac_blue.svg",
      ]
    },
    {
      title: "HI-SCAN 95586312-2is HD",
      slug: "hi-scan-130130t",
      short_desc: `
        The HI-SCAN 130130T-2is has been designed for the inspection of heavy freight and Euro pallets.
        Its low installation height is ideal for conveyor belts, and the connection of supple-mentary 
        feed and discharge conveyor systems for heavy freight is simple.
      `,
      desc: 
        <>
          <p>The HI-SCAN 130130T-2is has been designed for the inspection of heavy freight and Euro pallets.</p>
          <p>Its low installation height is ideal for conveyor belts, and the connection of supple- mentary feed and discharge conveyor systems for heavy freight is simple.</p>
          <p>It is equipped with two generators beaming in two directions (dual view). The second beam direction is displaced at 90°, facilitating reliable inspections of even tightly packed objects in a single process, shortening inspection times and increas- ing effectiveness.</p>
          <p>Typical penetration is 35mm of steel in view 2 and 30mm in view 1, while the maxi- mum conveyor load is 250kg (550lbs).</p>
        </>,
      image: "/images/smiths/130130T-2is.png",
      broschure: "/images/smiths/130130T_Datasheet.pdf",
      features: [
        'Specially developed for integration into conveyor systems',
        'Dual view for shorter inspection times',
        'Low conveyor belt allows for easy handling of heavy objects',
        'HiTraX technology employing real-time image processing',
        'HI-MAT Plus technology for better material distinction',
        'Ergonomically designed operating console with user definable key functions',
      ],
      technical_specs: [
        {label: "Max. object size", value: <>1,300 (W) x 1,300 (H) [mm]<br/>51.2 (W) x 51.2 (H) [in]</>},
        {label: "Dimensions", value: <>3,720 (L) x 2,100 (W) x 2,265 (H) [mm]<br/>146.5 (L) x 82.7 (W) x 89.2 (H) [in]</>},
        {label: "Weight", value: <>approx. 2,200kg <br/>approx. 4,850lbsat</>},
      ],
      serials: [
        "/images/serials/tsa_blue.svg"
      ]
    },
    {
      title: "HI-SCAN 130130T",
      slug: "hi--scan-130130t",
      short_desc: `
        The HI-SCAN 100100T is a state-of-the art X-ray inspection system for scanning objects up to a size of 100 x 100cm.
        Designed to meet the needs of airports, customs facilities, transportation operations, 
        carriers and parcel services – in applications where high security and the screening of large objects are required.
      `,
      desc: 
      <>
        <p>The HI-SCAN 100100T is a state-of-the art X-ray inspection system for scanning objects up to a size of 100 x 100cm.</p>
        <p>Designed to meet the needs of airports, customs facilities, transportation operations, carriers and parcel services – in applications where high security and the screening of large objects are required.</p>
        <p>The low installation height of the conveyor system facilitates the connection of supplementary feed and/or discharge conveyor systems for heavy freight.</p>
      </>,
      image: "/images/smiths/11-Hi-Scan100100T-2is-1.png",
      broschure: "/images/smiths/95586312.pdf",
      features: [
        'Ideal for inspecting over-sized baggage and freight',
        'Low conveyor belt allows for easy handling of heavy goods',
        'Outstanding image quality with HiTraX electronics',
        'Wire detectability AWG 38',
        'HI-MAT Plus technology provides better material distinction',
        'Tunnel size in optimal proportion to entire system',
        'Typical steel penetration 32mm',
      ],
      technical_specs: [
        {label: "Max. object size", value: <>1000 (W) x 1000 (H) [mm]<br/>39.4 (W) x 39.4 (H) [in]</>},
        {label: "Dimensions", value: <>3578 (L) x 1234 (W) x 1900 (H) [mm]<br/>141.0 (L) x 48.6 (W) x 74.8 (H) [in]</>},
        {label: "Weight", value: <>approx. 930kg<br/>approx. 2,050lbs</>},
      ],
      serials: [
        "/images/serials/icmore_weapons_blue.svg",
        "/images/serials/icmore_lithium_blue.svg",
      ]
    },
    {
      title: "HI-SCAN 6040C",
      slug: "hi-scan-6040c",
      short_desc: `
        HI-SCAN 6040C is designed to screen bags, parcels and other objects for prohibited and dangerous items at building entrances.
        Generating highly detailed, easily interpreted X-ray images, with a background-con- trast function that improves the recognizability of objects on the screen.
      `,
      desc: 
      <>
        <p>HI-SCAN 6040C is designed to screen bags, parcels and other objects for prohibited and dangerous items at building entrances.</p>
        <p>Generating highly detailed, easily interpreted X-ray images, with a background-con- trast function that improves the recognizability of objects on the screen.</p>
        <p>Intelligent image-display functions support the operator in the rapid and reliable distinction of organic and inorganic threats.</p>
        <p>It can also provide real-time diagnostics on its operating status, ensuring easy han- dling and troubleshooting.</p>
      </>,
      image: "/images/smiths/HI-SCAN-6040C.png",
      broschure: "/images/smiths/6040C Datasheet.pdf",
      features: [
        'Designed for use at building entrances',
        'High reliability',
        'Best-in-class image quality',
        'Easy to operate',
        'Upgradable electronics and software',
        'Uses next-generation HiTrax Software & Hardware',
        'Cost-optimised',
      ],
      technical_specs: [
        {label: "Max. object size", value: <>2,004 (L) x 850 (W) x 1,395 (H) mm<br/>78.9 (L) x 33.5 (W) x 54.9 (H) [in]</>},
        {label: "Dimensions", value: <>615 (W) x 410 (H) mm<br/>24.2 (W) x 16.1 (H) [in]</>},
        {label: "Weight", value: <>approx. 460kg<br/>approx. 1,014lbs</>},
      ],
      serials: [
        "/images/serials/icmore_weapons_blue.svg"
      ]
    },
    {
      title: "HI-SCAN 6040ctix",
      slug: "hi-scan-6040ctix",
      short_desc: `
        The HI-SCAN 6040 CTiX and the updated Model S offer advanced screening of 
        carry-on baggage using Computed Tomography (CT) and automatic explosives detection algorithms. 
      `,
      desc:
      <>
        <p>The HI-SCAN 6040 CTiX and the updated Model S offer advanced screening of carry-on baggage using Computed Tomography (CT) and automatic explosives detection algorithms. In addition to both models achieving ECAC EDS CB C3, HI-SCAN 6040 CTiX is also STAC, ENAC and IPMO approved; and has TSA APSS v6.2 Level 1 certification. These are also currently underway for HI-SCAN 6040 CTiX Model S. ECAC EDS CB C3 approved systems eliminate the need to remove electronic devic- es, liquids and gels from hand luggage– expediting screening and making the whole process less stressful for passengers.</p>
        <p>The gantry rotates at a constant speed as baggage is carried through it on the conveyor belt. Hundreds of images are taken to create high quality 3D images in real-time, allowing for more accurate judgments on the bag contents. The user interface received the 2020 Red Dot Award for fast and intuitive 3D image evalua- tion.</p>
        <p>Due to the considerable reduction in width by 20cm and weight by 350kg, the HI-SCAN 6040 CTiX Model S is easily integrated into existing Smiths Detection and third-party checkpoints as well as checkpoints with size and layout limitations.</p>
      </>,
      image: "/images/smiths/HI-SCAN-6040ctix.png",
      broschure: "/images/smiths/6040ctix Datasheet.pdf",
      features: [
        'ECAC and STAC EDS CB C3 approved',
        'TSA APSS 6.2 Level 1 certified',
        'Qualified on the TSA’s Checkpoint Property Screening System (CPSS) Qualified Products List (QPL) for base, mid and full-size configurations',
        'High throughput levels through highest belt speed in its class (0.2m/s)',
        '5-20% lower power consumption compared to alternative systems',
        'Low noise level (60 dB), zero vibration',
        'High detection rate and low false-alarm rate thanks to MAT technology',
        'Easily integrated into existing checkpoints, supporting open architecture solutions',
        'Optimized weight and footprint of the Model S',
      ],
      technical_specs: [
        {label: "Max. object size", value: <>3,300 (L) x 1,670 (W) x 1,670 (H) [mm]<br/>129.9 (L) x 65.7 (W) x 65.7 (H) [in]</>},
        {label: "Dimensions", value: <>620 (W) x 420 (H) [mm]<br/>24.4 (W) x 16.5 (H) [in]</>},
        {label: "Weight", value: <>approx. 2,450kg (5,401 lbs)</>},
        {label: "Conveyor Speed", value: <>0.2 (m/s)</>},
      ],
      serials: [
        "/images/serials/ecac_blue.svg",
        "/images/serials/tsa_blue.svg",
        "/images/serials/icmore_prohibited_blue.svg"
      ]
    },
    {
      title: "HI-SCAN 6040i",
      slug: "hi-scan-6040i",
      short_desc: `
        This X-ray unit is for screening personal belongings and cabin baggage at airport checkpoints and critical infrastructure.
        It is easy to operate and increases throughput at security checkpoints. Its material discrimination technology ensures reliable image analysis.
      `,
      desc:
      <> 
        <p>This X-ray unit is for screening personal belongings and cabin baggage at airport checkpoints and critical infrastructure.</p>
        <p>It is easy to operate and increases throughput at security checkpoints. Its material discrimination technology ensures reliable image analysis.</p>
        <p>The HI-SCAN 6040i is the most successful X-ray inspection system in its class, with more than 12,000 installations worldwide.</p>
      </>,
      image: "/images/smiths/HI-SCAN-6040i.png",
      broschure: "/images/smiths/6040i Datasheet.pdf",
      features: [
        'One of the most sold systems world-wide',
        'Easy to operate',
        'Powerful 140kV generator for steel penetration up to 31mm',
        'Integrates with iLane checkpoint designs'
      ],
      technical_specs: [
        {label: "Max. object size", value: <>615 (W) x 410 (H) [mm]<br/>24.2” (W) x 16.1” (H)</>},
        {label: "Dimensions", value: <>2,004 (L) x 850 (W) x 1,284 (H) [mm]<br/>78.9 (L) x 33.5 (W) x 50.6” (H) [in]</>},
        {label: "Weight", value: <>approx. 400kg<br/>approx. 881.9lbs</>},
      ],
      serials: [
        "/images/serials/icmore_weapons_blue.svg"
      ]
    }
  ],
  astrophysics: [
    {
      title: "XIS-1080™",
      slug: "xis-1080tm",
      short_desc: `
        The XIS-1080™ is a mid-size X-Ray Inspection System (XIS) designed for 
        Cargo Inspec- tion with a heavy conveyor belt capable of supporting heavy luggage and packages inspections. 
      `,
      desc: 
        <>
          <p>
            The XIS-1080™ is a mid-size X-Ray Inspection System (XIS) designed for Cargo Inspec- tion 
            with a heavy conveyor belt capable of supporting heavy luggage and packages inspections. 
            Equipped with a powerful generator and Astrophysics high-perfor- mance imaging software, 
            the XIS-1080™ delivers superior image quality, high throughput and greater accuracy for busy checkpoints
          </p>
        </>,
      image: "/images/astrophysics/XIS_1080.png",
      broschure: "/images/astrophysics/xis-1080_brochure.pdf",
      features: [
        'High-resolution images with 24-bit color',
        'Multi-colored material discrimination',
        'Windows Operating System with Intel® Core™ Processor',
        'Real-time diagnostics',
        'High-contrast image analysis',
      ],
      technical_specs: [
        {label: "Generator", value: "180kV"},
        {label: "Tunnel Size", value: '100.0 cm x 80.0 cm (39.4" x 31.5")'},
        {label: "Length", value: '260 cm (102.4")'},
        {label: "Width", value: '137.2 cm (54.0")'},
        {label: "Height", value: '147.3 cm (58.0")'}
      ]
    },
    {
      title: "XIS-7858™",
      slug: "xis-7858tm",
      short_desc: `
        The XIS-7858™ is a robust and reliable X-Ray Inspection System (XIS), 
        designed with both a narrow frame and large tunnel enabling it to screen a wide range of bags and parcels. 
      `,
      desc: 
        <>
          <p>
            The XIS-7858™ is a robust and reliable X-Ray Inspection System (XIS), 
            designed with both a narrow frame and large tunnel enabling it to screen a wide range of bags and parcels. Equipped with a full range of Astrophysics’ 
            industry-leading imaging tools, the system enables operators to manipulate scans in real-time and perform rapid, in-depth inspections of complex baggage.
          </p>
          <p>The XIS-7858™’s powerful systems help operators quickly distinguish between threatening and non-threatening objects, making this system ideal for a variety of security missions.</p>
        </>,
      image: "/images/astrophysics/xis-7858.png",
      broschure: "/images/astrophysics/xis-7858_brochure.pdf",
      features: [
        'Minimal footprint',
        'High-resolution images with 24-bit color',
        'Enhanced material discrimination',
        'Windows Operating System with Intel® Core™ Processor',
        'Real-time diagnostics',
        'High-contrast image analysis',
      ],
      technical_specs: [
        {label: "Generator", value: "180kV, Operating at 165kV"},
        {label: "Tunnel Size", value: '77.8 cm x 58.0 cm (30.6” x 22.8”)'},
        {label: "Length", value: '229.3 cm (90.3")'},
        {label: "Width", value: '100.6 cm (39.6")'},
        {label: "Height", value: '140.0 cm (55.1")'}
      ]
    },
    {
      title: "XIS-100XD™",
      slug: "xis-100xdtm",
      short_desc: `
        The XIS-100XD™ is a compact X-Ray Inspection System (XIS) designed for screening large baggage and heavy cargo. 
        Its unique downshooter configuration provides remarkable detail of densely packed objects, 
        while Astrophysics’ imaging software ensures precise object identification.
      `,
      desc: 
      <>
        <p>The XIS-100XD™ is a compact X-Ray Inspection System (XIS) designed for screening large baggage and heavy cargo. Its unique downshooter configuration provides remarkable detail of densely packed objects, while Astrophysics’ imaging software ensures precise object identification.</p>
        <p>With its slim operating profile the XIS-100XD™ is well-suited for nearly any location, and its low conveyor helps speed up loading and unloading freight.</p>
      </>,
      image: "/images/astrophysics/xis-100xd.png",
      features: [
        'High-resolution images with 24-bit color',
        'Enhanced material discrimination',
        'Repositioned generator for reduced footprint',
        'Windows Operating System with Intel® Core™ Processor',
        'Real-Time Diagnostics',
        'High-contrast image analysis',
      ],
      technical_specs: [
        {label: "Generator", value: "180kV"},
        {label: "Tunnel Size", value: '100.9 cm x 100.0 cm (39.7" x 39.4")'},
        {label: "Length", value: '239.5 cm (94.3")'},
        {label: "Width", value: '127.5 cm (50.2")'},
        {label: "Height", value: '191.0 cm (75.2")'}
      ]
    },
    {
      title: "XIS-1517",
      slug: "xis-1517",
      short_desc: `
        The XIS-1517 200kV™ is an X-Ray Inspection System (XIS) designed to scan baggage and hold cargo. 
        It is equipped with a 200kV generator for improved steel penetra- tion and a heavy-duty roller bed to speed the loading and unloading of freight.
      `,
      desc: 
      <>
        <p>The XIS-1517 200kV™ is an X-Ray Inspection System (XIS) designed to scan baggage and hold cargo. It is equipped with a 200kV generator for improved steel penetra- tion and a heavy-duty roller bed to speed the loading and unloading of freight.</p>
        <p>With Astrophysics comprehensive visual software, the XIS-1517 200kV™ allows operators to perform quick and thorough inspections of dense cargo pallets, making it the ideal solution for high-volume checkpoints that require both power and precision.</p>
      </>,
      image: "/images/astrophysics/XIS-1517.png",
      broschure: "/images/astrophysics/xis-1517_Brochure.pdf",
      features: [
        '200kV generator with increased steel penetration',
        'Heavy-duty roller bed',
        'High-resolution images with 24-bit color',
        'Multi-colored material discrimination',
        'Windows Operating System with Intel® Core™ Processor',
        'Real-Time Diagnostics',
        'High-contrast image analysi',
      ],
      technical_specs: [
        {label: "Generator", value: "180kV"},
        {label: "Tunnel Size", value: '100.0 cm x 80.0 cm (39.4" x 31.5")'},
        {label: "Length", value: '260 cm (102.4")'},
        {label: "Width", value: '137.2 cm (54.0")'},
        {label: "Height", value: '147.3 cm (58.0")'}
      ]
    },
    {
      title: "XIS-5878™",
      slug: "xis-5878tm",
      short_desc: `
        The Cargo X-Ray Security Scanner XIS-5878™ is a compact X-Ray Inspection System (XIS) designed to inspect cargo, cluttered bags, and parcels. 
        Its powerful generator delivers crisp, high-resolution images that make it easy for operators to perform inspections, 
        and its low conveyor makes loading and unloading freight more conve- nient.
      `,
      desc:
      <>
        <p>The Cargo X-Ray Security Scanner XIS-5878™ is a compact X-Ray Inspection System (XIS) designed to inspect cargo, cluttered bags, and parcels. Its powerful generator delivers crisp, high-resolution images that make it easy for operators to perform inspections, and its low conveyor makes loading and unloading freight more conve- nient.</p>
        <p>Equipped with Astrophysics’ comprehensive software tools, the Cargo X-Ray Securi- ty Scanner XIS-5878™ increases accuracy, minimizes false alarms, and boosts throughput at busy checkpoints, making it the ideal system for critical inspections in crowded environments.</p>
      </>,
      image: "/images/astrophysics/XIS-5878.png",
      features: [
        'Small Footprint',
        'High-resolution images with 24-bit color',
        'Enhanced material discrimination',
        'Windows Operating System with Intel® Core™ Processor',
        'Real-Time Diagnostics',
        'High-Contrast Image Analysis'
      ],
      technical_specs: [
        {label: "Generator", value: "180kV"},
        {label: "Tunnel Size", value: '180.7 cm x 180.4 cm (71.1" x 71.0")'},
        {label: "Length", value: '853.1 cm (335.9")'},
        {label: "Width", value: '289.6 cm (114.0")'},
        {label: "Height", value: '316.8 cm (124.7")'}
      ]
    }
  ],

  rapiscan: [
    {
      title: "GEMINI 6040",
      slug: "gemini-6040",
      short_desc: `
        Transmission plus Z Backscatter® X-ray inspection in one system.
        The ground-breaking Gemini® system combines a dual-energy tranmission X-ray
        with a Z Backscatter® X-ray in one system.
      `,
      desc: 
        <>
          <p>Transmission plus Z Backscatter® X-ray inspection in one system. The ground-breaking Gemini® system combines a dual-energy tranmission X-ray with a Z Backscatter® X-ray in one system.</p>
          <p>It detects both metallic (inorganic) and organic threats—even in cluttered environments for more comprehensive detection than transmission-only systems.</p>
          <h3>GROUND-BREAKING PARCEL INSPECTION</h3>
          <p>Ground-breaking Gemini®parcel X-ray inspection system combines dual-energy transmission with patented Z Backscatter®technology for the most comprehensive threat detection available for parcel, baggage, and mail screening. The Gemini®sys- tem’s unique capability to detect both metallic and non-metallic threats—even in cluttered environments—makes it an invaluable inspection tool for security officials.</p>
          <h3>POWERFUL COMBINATION OF TECHNOLOGIES</h3>
          <p>The power of the Gemini® system lies in its ability to simultaneously detect both inorganic and organic materials by combining dual-energy transmission and Z Backscatter® X-rays two complementary, advanced, and commercially proven tech- nologies. Together, they provide the most information available about the contents of a parcel.</p>
          <h3>MULTI-TECHNOLOGY</h3>
          <p>The Gemini®system’s dual-energy transmission X-rays generate a high-resolution image in which metallic threats, such as guns and knives, are easily detected and fine details, such as tiny wires that could indicate an improvised explosive device, can be discerned. Dual-energy transmission technology uses two X-ray energy levels to determine the “effective” atomic number of objects in the parcel and then colorizes the image based on material type.</p>
          <p>The Gemini®system’s Z Backscatter®X-rays generate a photo-like image in which organic materials—such as sheet, bulk, and liquid explosives, narcotics, and plastic weapons—are bright white. The easy-to-interpret images produced by Z Backscat- ter®technology also help to reduce operator fatigue.</p>
        </>,
      image: "/images/rapiscan/GEMINI_6040.png",
      features: [
        '635 MM X 440 MM Tunnel Size',
        'Improved Detection Over Transmission-only Systems',
        'Additional Backscatter Technology For Enhanced Threat Detection',
        'Increased Throughput With Faster Image Interpretation',
        'Intuitive User Interface',
        "Ergonomic Operator's Console",
        "Multi-technology System With Dual-energy Transmission & Backscatter",
        "Same Footprint As Transmission-only Systems",
      ],
      technical_specs: [
        {label: "Tunnel Opening", value: "635 mm x 440 mm (25 x 17.3 in)"},
        {label: "Length", value: '1980 mm (78 in)'},
        {label: "Width", value: '850 mm (33.5 in)'},
        {label: "Height", value: '1350 mm (53.3 in)'}
      ]
    },
    {
      title: "GEMINI 7555",
      slug: "gemini-7555",
      short_desc: `
        Two technologies in one system for the best system available to screen parcels, bags, and mail for threats and contraband.
        With two technologies in one system, the Gemini® 7555 X-ray inspection system, easily detects metallic objects like knives and guns, 
        but it also clearly reveals organic materials hidden in cluttered parcels which frequently can be missed in transmis- sion-only systems.
      `,
      desc: 
        <>
          <p>Two technologies in one system for the best system available to screen parcels, bags, and mail for threats and contraband.</p>
          <p>With two technologies in one system, the Gemini® 7555 X-ray inspection system, easily detects metallic objects like knives and guns, but it also clearly reveals organic materials hidden in cluttered parcels which frequently can be missed in transmis- sion-only systems.</p>
          <h3>GROUND-BREAKING PARCEL INSPECTION</h3>
          <p>Ground-breaking Gemini®parcel X-ray inspection system combines dual-energy transmission with patented Z Backscatter®technology for the most comprehensive threat detection available for parcel, baggage, and mail screening. The Gemini®sys- tem’s unique capability to detect both metallic and non-metallic threats—even in cluttered environments—makes it an invaluable inspection tool for security officials.</p>
          <h3>POWERFUL COMBINATION OF TECHNOLOGIES</h3>
          <p>The power of the Gemini® system lies in its ability to simultaneously detect both inorganic and organic materials by combining dual-energy transmission and Z Backscatter® X-rays two complementary, advanced, and commercially proven tech- nologies. Together, they provide the most information available about the contents of a parcel.</p>
          <h3>MULTI-TECHNOLOGY</h3>
          <p>The Gemini®system’s dual-energy transmission X-rays generate a high-resolution image in which metallic threats, such as guns and knives, are easily detected and fine details, such as tiny wires that could indicate an improvised explosive device, can be discerned. Dual-energy transmission technology uses two X-ray energy levels to determine the “effective” atomic number of objects in the parcel and then colorizes the image based on material type.</p>
          <p>The Gemini®system’s Z Backscatter®X-rays generate a photo-like image in which organic materials—such as sheet, bulk, and liquid explosives, narcotics, and plastic weapons—are bright white. The easy-to-interpret images produced by Z Backscat- ter®technology also help to reduce operator fatigue.</p>
        </>,
      image: "/images/rapiscan/GEMINI_7555.png",
      features: [
        '780 MM X 580 MM Tunnel Size',
        'Improved Detection Over Transmission-only Systems',
        'Additional Backscatter Technology For Enhanced Threat Detection',
        'Increased Throughput With Faster Image Interpretation',
        'Intuitive User Interface',
        "Ergonomic Operator's Console",
        "Multi-technology System With Dual-energy Transmission & Backscatter",
        "Same Footprint As Transmission-only Systems",
      ],
      technical_specs: [
        {label: "Tunnel Opening", value: "780 mm x 580 mm (30.7 x 22.8 in)"},
        {label: "Length", value: '2900 mm (114.0 in)'},
        {label: "Width", value: '1050 mm (41.4 in)'},
        {label: "Height", value: '1500 mm (58.9 in)'}
      ]
    },
    {
      title: "ORION® 918CX",
      slug: "orion-918cx",
      short_desc: `
        The 918CX is a high performance, compact and versatile 540mm by 360mm tunnel X-Ray screening system 
        with exceptional image quality and excellent threat detec- tion alert capabilities.
        Orion® 918CX offers best in class spatial resolution providing exceptional image quality but it's small size 
        make it an ideal solution for schools, hotels, convention centers and event-based security environment
      `,
      desc: 
        <>
          <p>The 918CX is a high performance, compact and versatile 540mm by 360mm tunnel X-Ray screening system with exceptional image quality and excellent threat detec- tion alert capabilities.</p>
          <p>Orion® 918CX offers best in class spatial resolution providing exceptional image quality but it's small size make it an ideal solution for schools, hotels, convention centers and event-based security environment.</p>
          <h3>SPECTRUM 4-COLOR IMAGING</h3>
          <p>918CX now can exhibit images in the Classic 4-color and the new proprietary Spec- trum 4-color (SP4) option providing superior image, allowing improved security by quick and accurate identification of threats and increase in throughput.</p>
          <h3>EXPLOSIVES AND NARCOTICS DETECTION</h3>
          <p>Target and NARCScan are designed to detect a wide range of explosives and narcot- ics respectively in real time during the scanning process by marking a potential threat on the X-ray image. Rapiscan® Systems detection algorithms are based on regulatory approved material analysis techniques.</p>
          <h3>DUAL MODE ZEFF</h3>
          <p>This feature helps operators identify organics accurately and quickly either in Range Mode which highlights the areas based on selected Z-effective numbers of 7, 8 or 9 by the operator, or in Interactive Mode which gives the operator the option to display the Z-effective number in all the areas of the scanned item based on the value of the pixel. OrionTM family are the only systems in industry that can support both Range Mode and Interactive Mode.</p>
          <h3>EUROPEAN AVIATION COMPLIANCE</h3>
          <p>918CX performance is in full compliance with the mandatory regulations (EC) No. 300/2008, (EU) No. 185/2010 and commissions decision C(2010)774 for aviation security in the European Union.</p>
        </>,
      image: "/images/rapiscan/ORION_918CX.png",
      features: [
        'Industry Best Spatial Resolution',
        'Explosives & Narcotics Detection',
        'Low Operating Power Consumption',
        'Matrixing, Networking & Remote Viewing',
        'Higher Operational Availability - Significant Reduction In Customer Down Time',
        "Compliant With European Commission Aviation Security Requirements",
        "64-bit Windows 10 Operating System",
        "Regulatory Based Explosives Detection Algorithms",
        "17 Image Processing Functions",
        "Spectrum 4-color Imaging",
        "Sinergy Low Density Image Enhancement",
        "Dual Mode Z-effective",
        "Automatic, Enhanced Image Archiving"
      ],
      technical_specs: [
        {label: "Tunnel size", value: "540 x 360 mm (21.3 x 14.2 in)"},
        {label: "Length", value: '1,657 mm (65.2 in)'},
        {label: "Width", value: '794 mm (31.3 in)'},
        {label: "Height", value: '1,356 mm (53.3 in)'}
      ]
    },
    {
      title: "ORION® 920CX",
      slug: "orion-920cx",
      short_desc: `
        The 920CX is a 620mm x 420mm single view screening system providing exceptional 
        threat detection and high throughput by virtue of Best in Industry Penetration and Resolution.
      `,
      desc: 
      <>
        <p>The 920CX is a 620mm x 420mm single view screening system providing exceptional threat detection and high throughput by virtue of Best in Industry Penetration and Resolution.</p>
        <p>ORION® 920CX provides the best measured steel penetration, wire resolution and spatial resolution vs. the stated performance of any other X-ray screening system in the industry. With superior image quality & exceptional threat detection it is ideal for high throughput checkpoint environments.</p>
      </>,
      image: "/images/rapiscan/920CX.png",
      features: [
        '620 MM X 420 MM Tunnel Size',
        'Improved Detection Over Transmission-only Systems',
        'Additional Backscatter Technology For Enhanced Threat Detection',
        'Increased Throughput With Faster Image Interpretation',
        'Intuitive User Interface',
        "Ergonomic Operator's Console",
        "Multi-technology System With Dual-energy Transmission & Backscatter",
        "Same Footprint As Transmission-only Systems",
      ],
      technical_specs: [
        {label: "Tunnel Size", value: '620 mm x 420 mm (24.4 in x 16.5 in)'},
        {label: "Length", value: '2,082 mm (82. in)'},
        {label: "Width", value: '869 mm (34.2 in)'},
        {label: "Height", value: '1,377 mm (54.2 in)'}
      ]
    },
    {
      title: "ORION® 922CX",
      slug: "orion-922cx",
      short_desc: `
        The new 922CX is a High Performance 750mm by 550mm tunnel checkpoint screen-
        ing system with best in industry resolution and excellent threat detection alert capabilities.
      `,
      desc:
      <>
        <p>The new 922CX is a High Performance 750mm by 550mm tunnel checkpoint screening system with best in industry resolution and excellent threat detection alert capabilities.</p>
        <p>ORION® 922CX oers superior wire resolution and spatial resolution vs. the stated performance of competitive X-ray screening systems. With it's larger tunnel size it is ideal for large carry-on baggage and parcels at the checkpoint.</p>
        <h3>SPECTRUM 4-COLOR IMAGING</h3>
        <p>922CX now can exhibit images in the Classic 4-color and the new proprietary Spectrum 4-color (SP4) option providing superior image, allowing improved security by quick and accurate identication of threats and increase in throughput.</p>
        <h3>DUAL MODE ZEFF</h3>
        <p>This feature helps operators identify organics accurately and quickly either in Range Mode which highlights the areas based on selected Z-eective numbers of 7, 8 or 9 by the operator, or in Interactive Mode which gives the operator the option to display the Z-eective number in all the areas of the scanned item based on the value of the pixel. OrionTM family are the only systems in industry that can support both Range Mode and Interactive Mode.</p>
        <h3>EUROPEAN AVIATION COMPLIANCE</h3>
        <p>922CX performance is in full compliance with the mandatory regulations (EC) No.300/2008, (EU) No. 185/2010 and commissions decision C(2010)774 for aviation security in the European Union.</p>
      </>,
      image: "/images/rapiscan/922CX.png",
      features: [
        'Industry Best Wire & Spatial Resolution',
        'Explosives & Narcotics Detection',
        'Low Operating Power Consumption',
        'Matrixing, Networking & Remote Viewing',
        'Higher Operational Availability - Significant Reduction In Customer Down Time',
        'Compliant With European Commission Aviation Security Requirements',
        '64-bit Windows 10 Operating System',
        'Regulatory Based Explosives Detection Algorithms',
        '17 Image Processing Functions',
        'Sinergy Low Density Image Enhancement',
        'Spectrum 4 - Color Imaging',
        'Multi‐system Alarm Resolution Network',
        'Automatic, Enhanced Image Archiving'
      ],
      technical_specs: [
        {label: "Tunnel Size", value: '750 mm x 550 mm (29.4 in x 21.5 in)'},
        {label: "Length", value: '2,282 mm (89.9 in)'},
        {label: "Width", value: '1,049 mm (41.3 in)'},
        {label: "Height", value: '1,489 mm (58.6 in)'}
      ]
    }
  ]
}

export default SCANNERS
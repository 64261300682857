import { AnimationOnScroll } from "react-animation-on-scroll";
import Anchor from "../../components/Anchor/Anchor";
import Banner from "../../components/Banner/Banner";
import BlueBanner from "../../components/Banner/BlueBanner";
import ServiceBanner from "../../components/Banner/ServiceBanner";
import ImageBackgroundBox from "../../components/Boxes/ImageBackgroundBox";
import Grid from "../../components/Layout/Grid/Grid";
import ServiceLayout from "../../components/Layout/Content/ServiceLayout";
import HalfImage from "../../components/Layout/Content/HalfImage";
import styles from "./home.module.css";
import Page from '../../routes/Page'

function Home() {
  const data = [
    {
      image: "/images/safety.png",
      title: "OUR PRIORITY IS SAFETY!",
      desc: "At Scantech, safety is our top priority in everything we do. We are committed to providing services that not only meet our clients' needs but also ensure the highest levels of safety and security.",
      link: {to:"/our-priority", title:"Learn more", transparent: true}
    },
    {
      image: "/images/xray.png",
      elmId: "xray-scanners",
      title: "Scantech X-ray Scanners",
      desc: "Scantech is a leading provider of state-of-the-art X-ray scanning solutions that deliver exceptional imaging capabilities for various industries. Our X-ray scanners are designed to uncover hidden details, detect threats, and ensure the utmost security and accuracy in inspections.",
      reverse: true
    },
  ];

  const backgroundBox = [
    {
      img: "image1.png",
      elmId: "smart-tech",
      title: "Smart Tech Services",
      desc: "At Scantech, we specialize in providing cutting-edge smart tech services that enhance the functionality, efficiency, and convenience of your home or business. With our expertise in the latest smart technologies, we can help you harness the power of automation and connectivity to create an intelligent space tailored to your specific needs.",
      link: "/smart-tech",
      anchor: "Learn more",
    },
    {
      img: "image2.png",
      elmId: "professional-trainings",
      title: "Professional Training",
      desc: "At Scantech, we understand the importance of continuous learning and professional development in today's competitive landscape. That's why we offer comprehensive professional training programs designed to equip you with the knowledge, skills, and expertise needed to excel in your chosen field.",
      link: "/",
      anchor: "Learn more",
    },
  ];

  const serviceData = {
    image: "serviceBackground.png",
    elmId: "electrity-installation",
    title: "Plumbing and Electrical Services",
    desc: "At Scantech, we offer a comprehensive range of professional plumbing and electrical services to meet the diverse needs of our residential and commercial clients. Our team of skilled technicians is dedicated to delivering reliable, efficient, and cost-effective solutions that ensure the smooth operation of your plumbing and electrical systems.",
    link: "/electrical-services",
    anchor: "Learn more about this service"
  };

  return (
    <Page title={"Homepage"}>
      <Banner />
      <div className="main_container">
        {data && data.map((item, index) => (
          <HalfImage 
            key={index}
            title={item.title}
            image={item.image}
            text={item.desc}
            elmId={item?.elmId}
            reverse={item?.reverse}
            customClass={styles.halfImageContainer}
            link={item?.link}
          />
        ))}
      </div>
      <BlueBanner />
      <div className="dots home_academy">
        <div className="main_container">
          <div className={styles.academy_section}>
            <Grid no={2}>
              <div id="academy">
                <AnimationOnScroll animateIn="animate__fadeInDown">
                  <h1 className="big_text">Scantech Academy</h1>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeInDown">
                  <p>
                    Scantech Academy is a prestigious institution committed to 
                    empowering individuals with the knowledge and skills necessary to 
                    thrive in the rapidly evolving digital landscape. We offer a wide 
                    range of comprehensive courses designed to equip students with practical 
                    expertise and a competitive edge in today's job market.
                  </p>
                </AnimationOnScroll>
                <div className={styles.service_layout}>
                  <ServiceLayout />
                </div>
              </div>
              <div className="end">
                <Anchor title="Learn more" link="/academy" />
              </div>
            </Grid>
          </div>
          <HalfImage 
            title="6 Courses"
            image="/images/student.png"
            text={`
              We understand the importance of quality education and its impact on your career prospects. 
              That's why all our courses have undergone a rigorous accreditation process, validating the curriculum, teaching methodologies, and learning outcomes. 
              This accreditation provides you with the assurance that your training meets the highest standards of excellence and relevance.
            `}
            link={{
              to: "/academy",
              title: "Learn more about our courses"
            }}
            customClass={styles.courses}
          />
        </div>
      </div>
      <ServiceBanner {...serviceData} />
      <div className="main_container">
        <Grid no={2}>
          {backgroundBox.map((i, j) => {
            return <ImageBackgroundBox {...i} key={j} />;
          })}
        </Grid>
      </div>
    </Page>
  );
}
export default Home;
